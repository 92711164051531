<template>
  <div class="hello">
    <!-- 导航栏 -->
    <div class="menu">
      <!-- logo -->
      <a href="#">
        <img class="Logo" src="./Footer_img/logo.png" alt="" />
      </a>
      <div class="box">
        <!-- 导航栏列表 -->
        <ul class="minMenu">
          <li class="title1" @click="openPage0()">大赛首页</li>
          <!-- <li class="title2" @click="openPage1()">
                        赛事介绍
                        <span id="topRealName"></span>
                            <b class="caret"></b>
                            <el-collapse-transition>
                                <div class="changeBox" v-show="pull_show ">
                                <p class="changePassword" @click="brief">大赛简介</p>
                                <p class="changeDisable"  @click="Regulations">大赛章程</p>
                                <p class="changePassword" @click="framework">组织架构</p>
                                <p class="changePassword" @click="retrospect">往届回顾</p>
                                <p class="changeDisable"  @click="journalism">新闻动态</p>
                                <p class="changeDisable"  @click="platform">平台介绍</p>
                            </div>
                            </el-collapse-transition>
                    </li> -->
          <li class="title3" @click="openPage2()">官方文件</li>
          <!-- <li class="title4" @click="openPage3()">赛区规划</li>
          <li class="title5" @click="openPage4()">大赛培训</li> -->
          <li class="title6" @click="openPage5()">联系我们</li>
          <div>
            <!-- <el-button @click="inpbut" type="primary" id="inp"
              >报名参赛</el-button
            > -->
            <!-- <img
              src="./Footer_img/button.png"
              @click="inpbut"
              id="inp"
              alt=""
            /> -->
            <span class="btnContent">
              <a href="https://fxyh-t.bocmartech.com/jbgs/#/login" target="_blank" >
                <img
                  src="./Footer_img/signUp.png"
                  alt=""
                  class="btnImg"
                  style="margin-top: .03rem;"
                />
              </a>
              <img
                src="./Footer_img/btn.png"
                @click="inpbut"
                alt=""
                class="btnImg"
              />
            </span>
          </div>
        </ul>
      </div>
    </div>
  </div>
</template>
  
  <script>
import $ from "jquery";
export default {
  name: "Home",
  components: {},
  data() {
    return {
      pull_show: false,
    };
  },
  methods: {
    openPage0() {
      this.$router.push("/HomePage");
    },
    openPage1() {
      this.pull_show = !this.pull_show;
      console.log(this.pull_show);
    },
    openPage2() {
      this.$router.push("/File");
    },
    openPage3() {
      this.$router.push("/fingerpost");
    },
    openPage4() {
      this.$router.push("/Train");
    },
    openPage5() {
      this.$router.push("/Contact");
    },
    brief() {
      console.log("大赛简介");
      this.$router.push("/Brief");
    },
    framework() {
      console.log("组织架构");
      this.$router.push("/Framework");
    },
    retrospect() {
      console.log("往届回顾");
      this.$router.push("/Retrospect");
    },
    journalism() {
      console.log("新闻动态");
      this.$router.push("/Journalism");
    },
    platform() {
      console.log("平台介绍");
      this.$router.push("/Platform");
    },
    Regulations() {
      console.log("大赛章程");
      this.$router.push("/Regulations");
    },
    inpbut() {
      // this.$message.error("大赛报名还未开启，敬请期待！");
      this.$router.push("/Register");
      // window.open('https://www.ncie.org.cn/Category_1397/Index.aspx', '_blank')
      // let target = evt.target; 
      // if (target.nodeName == "SPAN") {
      //   target = evt.target.parentNode;
      // }
      // target.blur();
    },
    signUp(){

    }
  },
  mounted() {
    // console.log(this.$route.name)
    if (this.$route.name == "HomePage") {
      $(".title1").addClass("divClass ");
    } else if (this.$route.name == "introduce") {
      $(".title2").addClass("divClass ");
    } else if (this.$route.name == "File") {
      $(".title3").addClass("divClass ");
    } else if (this.$route.name == "fingerpost") {
      $(".title4").addClass("divClass ");
    } else if (this.$route.name == "Train") {
      $(".title5").addClass("divClass ");
    } else if (this.$route.name == "Contact") {
      $(".title6").addClass("divClass ");
    }
  },
  created() {},
};
</script>
  
  <style scoped lang="scss">
.divClass {
  color: #ffffff !important;
}
@import "./Footer.scss";
</style>
  