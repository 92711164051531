<template>
  <!-- 首页 -->
  <div>
    <!-- 底部 -->
    <div class="Footer">
      <div class="Footerbox">
        <img class="logo_t" src="./Bottom_img/logo_t.png" alt="" />
        <span class="vertical"></span>
        <span class="unit">赛项承办单位:</span><br />
        <span class="unit2">大唐移动</span>
        <div class="box">
          <!-- 导航栏列表 -->
          <ul class="minMenu">
            <li class="title1" @click="openPage0()">大赛首页</li>
            <li class="title3" @click="openPage2()">官方文件</li>
            <li class="title6" @click="openPage5()">联系我们</li>
          </ul>
        </div>
      </div>

      <div class="home">
        <el-backtop></el-backtop>
        <div class="content"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showBtn: false, // 回到顶部，默认是false，就是隐藏起来
    };
  },

  created() {},
  mounted() {
    window.addEventListener("scroll", this.showbtn, true);
  },
  methods: {
    openPage0() {
      this.$router.push("/HomePage");
    },
    openPage5() {
      this.$router.push("/Contact");
    },
    openPage2() {
      this.$router.push("/File");
    },
    // 回到顶部
    showbtn() {
      let that = this;
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      that.scrollTop = scrollTop;
      if (scrollTop > 900) {
        this.showBtn = true;
      } else {
        this.showBtn = false;
      }
    },

    /*回到顶部实现过程注解：
        1.获取当前页面距离顶部的距离
        2.计算出每次向上移动的距离，用负的滚动距离除以5，因为滚动的距离是一个正数，想向上移动就是一个减法
        3.用当前距离加上计算出的距离，然后赋值给当前距离，就可以达到向上移动的效果
        4.最后在移动到顶部的时候，清除定时器1
        */

    backTop() {
      var timer = setInterval(function () {
        let osTop =
          document.documentElement.scrollTop || document.body.scrollTop;
        let ispeed = Math.floor(-osTop / 5);
        document.documentElement.scrollTop = document.body.scrollTop =
          osTop + ispeed;
        this.isTop = true;
        if (osTop === 0) {
          clearInterval(timer);
        }
      }, 10);
    },
  },
};
</script>

<style scoped lang="scss">
@import "./Bottom.scss";
</style>